body {
  background-image: url("../public/bg.jpg");
  font-family: sans-serif;
  /* font-family: "Roboto"; */
  /* width: 100vw; */
  overflow-x: hidden;
  scrollbar-width: none;
  /* font-family: "Roboto"; */
  /* width: 100vw; */
}

body::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  /* Adjust opacity and color as needed */
  z-index: -1;
  /* Ensures the overlay is behind the content */
}

.content-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px;
}

.keywords {
  display: inline-flex;
}

#image_grid {
  width: 95%;
  margin-top: 20%;
  /* margin-right: 5px; */
  /* padding-top: 8%; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-evenly; */
}

.card {
  border: 1px solid #ccc;
  /* Add border */
  border-radius: 5px;
  /* Add rounded corners */
  padding: 10px;
  /* Add padding */
  margin-bottom: 10px;
  /* Add margin between cards */
  cursor: pointer;
  /* Change cursor to pointer on hover */
  transition: all 0.3s ease;
  /* Add smooth transition */
  margin-top: 20px;

  border-bottom-style: solid;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 0.85rem;
  border-bottom-style: solid;
  border-color: #2a2a72;
  box-shadow: 10px 5px 5px grey;
  margin-top: 20px;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Add shadow on hover */
}

.card-title {
  font-size: 1.25rem;
  /* Larger font size for title */
  margin-bottom: 0.5rem;
  /* Add space below title */
}

.card-text {
  color: #555;
  /* Change text color for path */
}

#image_div {
  margin: 1%;
}

#image_div:hover {
  transition: 0.2s;
  transform: scale(1.025);
}

#score {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  /* user-select: none; */
  cursor: pointer;
}

#image_tag {
  height: 300px;
  object-fit: contain;
  cursor: pointer;
}

#article_div {
  cursor: pointer;
  transition: all 0.3s ease;
  border-bottom-style: solid;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 0.85rem;
  border-bottom-style: solid;
  border-color: #2a2a72;
  box-shadow: 10px 5px 5px grey;
  margin-top: 20px;
}

#article_div:hover {
  transform: scale(1.05);
}

#error_message {
  margin-top: 15%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  #image_grid {
    /* padding-top: 25%; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  #image_div {
    max-width: fit-content;
  }

  #image_div:hover {
    transition: 0.2s;
    transform: scale(1.025);
  }

  #image_tag {
    object-fit: contain;
    cursor: pointer;
    max-height: 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 800px) {
  #image_grid {
    /* padding-top: 20%; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: space-evenly; */
  }

  #image_div {
    max-width: fit-content;
  }

  #image_tag {
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1000px) {
  #image_grid {
    /* padding-top: 13%; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: space-evenly; */
  }

  #image_div {
    max-width: fit-content;
  }

  #image_tag {
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1200px) {
  #image_grid {
    /* padding-top: 10%; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: space-evenly; */
  }

  #image_div {
    max-width: fit-content;
  }

  #image_tag {
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  #image_grid {
    /* padding-top: 8%; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: space-evenly; */
  }

  #image_div {
    max-width: fit-content;
  }

  #image_tag {
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
  }
}

#article_grid {
  /* padding-top: 8%; */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

@media screen and (max-width: 600px) {
  #article_div h5 {
    font-size: 0.8em;
  }

  #article_div {
    /* color: black; */
    width: 100% !important;
  }
}

.p-5 {
  min-height: 150px;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#article_div {
  /* color: black; */

  width: 23%;
}

#article_body {
  height: 30vh;
  overflow: hidden;
  white-space: wrap;
  text-align: justify;
}

.rounded {
  border-radius: 0.85rem !important;
  box-shadow: 10px 3px 3px grey;
}

hr.hr-dot {
  overflow: visible;
  /* For IE */
  height: 30px;
  border-style: solid;
  border-color: black;
  border-width: 1px 0 0 0;
  border-radius: 20px;
  margin-bottom: 10px;
}

hr.hr-dot::before {
  display: block;
  content: "";
  height: 30px;
  margin-top: -31px;
  border-style: solid;
  border-color: black;
  border-width: 0 0 1px 0;
  border-radius: 20px;
}

.imageHover:hover {
  opacity: 0.7;
}

.cropper-container {
  position: relative;
  width: 100%;
  height: 350px;
  max-width: 100%;
  overflow: hidden;
}

.cropper-background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  width: 100%;
  height: 100%;
}
