.overview{
    max-width: 270px;
}

.overview-banner{
    height: 100vh;
    background-position-x : left;
    background-position-y: top;
}

.overview-side-banner{
    width: 270px;
    height: 267px;
    background-position-x : 50%;
    background-position-y: center;
}

.overview-title-main{
    font-size: 2.5rem;
    line-height: 4rem;
}

.overview-title{
    font-size: 24px;
}

.courseWidth{
    padding: 0 2rem;
}

.course-title{
    position: relative;
    font-size: 36px;
}

.course-title::after{
    display: block;
    margin-top: 3rem;
    content: '';
    width: 16rem;
    height: .3rem;
    background-color: #BED804;
    color: #BED804;
}


.topic-title{
    font-size: 28px;
}

.title-after{
    width: 25%;
}

.detail-description{
    font-size: 18px;
}

.youtube-title{
    font-size: 18px;
}

.move-section{
    font-size: 15px;
}

@media (max-width: 675px){
    .overview{
        max-width: 250px;
    }
    .overview-title-main{
        font-size: 2rem;
    }
    .overview-title{
        font-size: 22px;
    }
    .courseWidth{
        padding: 0;
    }
    .course-title{
        font-size: 30px;
    }
    .course-title::after{
        display: block;
        margin-top: 3rem;
        content: '';
        width: 12rem;
        height: .3rem;
        background-color: #BED804;
        color: #BED804;
    }
    .title-after{
        width: 30%;
    }
    .topic-title{
        font-size: 24px;
    }
    .detail-description, .youtube-title{
        font-size: 16px;
    }
}

@media(max-width: 550px){
    .overview-title-main{
        font-size: 1.7rem;
    }
    .course-title{
        font-size: 28px;
    }
    .topic-title{
        font-size: 22px;
    }
     .move-section{
        font-size: 13px;
    }

}

@media (max-width: 500px){
    .overview{
        max-width: 200px;
    }
    .course-title{
        font-size: 24px;
    }

    .course-title::after{
        display: block;
        margin-top: 3rem;
        content: '';
        width: 50%;
        height: .3rem;
        background-color: #BED804;
        color: #BED804;
    }

    .title-after{
        width: 50%;
    }

    .topic-title{
        font-size: 18px;
    }

    
    
}

@media (max-width: 475px){
    .overview{
        max-width: 100%;
    }
    .overview-title-main{
        line-height: 2rem;
        font-size: 1.5rem;
    }
    .overview-title{
        font-size: 20px;
    }
    .overview-side-banner{
        height: 200px;
    }

    .detail-description, .youtube-title{
        font-size: 14px;
    }

    .move-section{
        font-size: 11px;
    }

    
}
