@font-face {
  font-family: TheSans;
  src: url("./assets/TSaOf5__.ttf") format("truetype");
}
@font-face {
  font-family: TheSansI;
  src: url("./assets/TSaOf5i_.ttf") format("truetype");
}
@font-face {
  font-family: TheSans7;
  src: url("./assets/TSaOf7__.ttf") format("truetype");
}
@font-face {
  font-family: TheSans7I;
  src: url("./assets/TSaOf7i_.ttf") format("truetype");
}
.thesans {
  font-family: TheSans;
}
.thesansi {
  font-family: TheSansI;
}
.thesans7 {
  font-family: TheSans7;
}
.thesans7i {
  font-family: TheSans7I;
}

.container-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  height: 100px;
  width: 100px;
  animation: rotate_3922 1s linear infinite;
  background-color: #9b59b6;
  background-image: linear-gradient(#9b59b6, #84cdfa, #5ad1cd);
}

.video-container {
  position: relative;
  padding-top: 56.25%;
  /* Aspect ratio for 16:9 videos */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.preview-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff;
  /* Blue color for the icon */
  padding: 0;
}

.preview-button:hover {
  color: #0056b3;
  /* Darker blue on hover */
}

@keyframes rotate_3922 {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.container-loader span {
  position: absolute;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  background-color: #9b59b6;
  background-image: linear-gradient(#9b59b6, #84cdfa, #5ad1cd);
}

.container-loader span:nth-of-type(1) {
  filter: blur(5px);
}

.container-loader span:nth-of-type(2) {
  filter: blur(10px);
}

.container-loader span:nth-of-type(3) {
  filter: blur(25px);
}

.container-loader span:nth-of-type(4) {
  filter: blur(50px);
}

.container-loader::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background-color: #fff;
  border: solid 5px #ffffff;
  border-radius: 50%;
}

.bg-success {
  background-color: #bed804 !important;
}

.start-course-cls {
  background-color: #fff;
  color: #000;
  font-weight: bolder;
  font-size: small;
}

.start-course-cls:hover,
.start-course-cls:active {
  color: #000 !important;
}

/* .cover__header-content-title {
  line-height: 5.2rem !important;
  font-size: 4.5rem !important;
} */

.modal-content {
  width: 99vw;
  /* height: 100vh; */

  height: 800px;
  /* overflow: auto; */
}
.modal-dialog {
  margin: 0%;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 0%;
    /* margin: 1.75rem auto; */
  }
}

.tox-statusbar__branding {
  display: none !important;
}
.custom_icons {
  fill: #2a2a72 !important;
}
.preview-button:focus {
  box-shadow: transparent;
}
